import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyAFMCpwRywbF4gBiVTNqYKmlJdpk8qde4A",
    authDomain: "books-4bb59.firebaseapp.com",
    projectId: "books-4bb59",
    storageBucket: "books-4bb59.appspot.com",
    messagingSenderId: "1069604296603",
    appId: "1:1069604296603:web:a5f5d254304a503fdcb33e",
    measurementId: "G-HHV6SBVMY8"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);



export { db };


