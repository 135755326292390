import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Regions from './screens/Regions';
import Class7 from './screens/Class7';
import Class8 from './screens/Class8';
import Class9 from './screens/Class9';
import Class10 from './screens/Class10';
import Class11 from './screens/Class11';
import Info from './screens/Info';
import './App.css';


function App() {



  return (
    <Router>
      <div className='container'>
        {/* <nav>
          <ul>
            <li>
              <Link to="/regions">Regions</Link>
            </li>
            <li>
              <Link to="/filter">Filter</Link>
            </li>
            <li>
              <Link to="/info">Info</Link>
            </li>
          </ul>
        </nav> */}

        <Routes>
          <Route path="/" element={<Regions />} />
          <Route path="/class7" element={<Class7 />} />
          <Route path="/class8" element={<Class8 />} />
          <Route path="/class9" element={<Class9 />} />
          <Route path="/class10" element={<Class10 />} />
          <Route path="/class11" element={<Class11 />} />
          <Route path="/info" element={<Info />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
