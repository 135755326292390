import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Class9 = () => {
    const navigate = useNavigate();
    const [books, setBooks] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState({});
    const [selectedQuantities, setSelectedQuantities] = useState({});
    const [selectedSubjects, setSelectedSubjects] = useState({});
    const [isDataSaved, setIsDataSaved] = useState(false);

    const selectedDataDocId = localStorage.getItem('selectedDataDocId');

    useEffect(() => {
        fetch('/books.json')
            .then((response) => response.json())
            .then((data) => {
                const class9Books = data.filter((book) => book.class === '9');
                setBooks(class9Books);
            })
            .catch((error) => {
                console.error('Ошибка загрузки данных из books.json:', error);
            });
    }, []);

    const subjects = ['Физика', 'Химия', 'Геометрия', 'Алгебра', 'Биология'];

    const handleNextClick = async () => {
        // const areAllSelected = subjects.every((subject) => {
        //     const selectedLanguage = selectedLanguages[subject];
        //     const selectedQuantity = selectedQuantities[subject];
        //     const selectedSubject = selectedSubjects[subject];
        //     return selectedLanguage && selectedQuantity && selectedSubject;
        // });

        // if (!areAllSelected) {
        //     alert('Пожалуйста, выберите язык, предмет и количество для каждого предмета.');
        //     return;
        // }

        const class9Data = {};

        subjects.forEach((subject) => {
            const selectedLanguage = selectedLanguages[subject] || '';
            const selectedQuantity = selectedQuantities[subject] || '';
            // const selectedSubjects = selectedSubjects[subject] || '';
            const selectedBook = books.find((book) => book.name === selectedSubjects[subject] && book.lang === selectedLanguage);
            if (selectedLanguage && selectedQuantity) {
                
                class9Data[subject] = {
                    language: selectedLanguage,
                    quantity: selectedQuantity,
                    book: {
                        name: selectedBook.name,
                        author: selectedBook.author,
                        publisher: selectedBook.publisher,
                    },
                };
            }
        });

        const selectedDataDocRef = doc(db, 'selectedData', selectedDataDocId);

        try {
            await updateDoc(selectedDataDocRef, { class9: class9Data });
            setIsDataSaved(true);
            console.log('Данные успешно сохранены в Firestore.');
            navigate('/class10');
        } catch (error) {
            console.error('Ошибка при обновлении данных в Firestore:', error);
        }
    };

    const handleLanguageChange = (subject, value) => {
        setSelectedLanguages({
            ...selectedLanguages,
            [subject]: value,
        });
    };

    const handleQuantityChange = (subject, value) => {
        setSelectedQuantities({
            ...selectedQuantities,
            [subject]: value,
        });
    };

    const handleSubjectChange = (subject, value) => {
        setSelectedSubjects({
            ...selectedSubjects,
            [subject]: value,
        });
    };

    const uniqueLangs = [...new Set(books.map((book) => book.lang))];

    return (
        <div>
            <h1>Книги для 9 класса</h1>
            {subjects.map((subject) => (
                <div key={subject}>
                    <h2>{subject}</h2>
                    <div className="dropdowns">
                        <label>Язык</label>
                        <select
                            onChange={(e) => handleLanguageChange(subject, e.target.value)}
                            value={selectedLanguages[subject] || ''}
                        >
                            <option value="">Выберите язык</option>
                            {uniqueLangs.map((lang) => (
                                <option key={lang} value={lang}>
                                    {lang}
                                </option>
                            ))}
                        </select>

                        {selectedLanguages[subject] && (
                            <>
                                <label>Предмет</label>
                                <select
                                    onChange={(e) => handleSubjectChange(subject, e.target.value)}
                                    value={selectedSubjects[subject] || ''}
                                >
                                    <option value="">Выберите предмет</option>
                                    {books
                                        .filter((book) => book.subject === subject && book.lang === selectedLanguages[subject])
                                        .map((book) => (
                                            <option key={book.name} value={book.name}>
                                                {book.name}, {book.author}, {book.publisher}
                                            </option>
                                        ))}
                                </select>

                                {selectedSubjects[subject] && (
                                    <>
                                        <label>Количество</label>
                                        <input
                                            type="number"
                                            min="1"
                                            onChange={(e) => handleQuantityChange(subject, e.target.value)}
                                            value={selectedQuantities[subject] || ''}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            ))}
            <button className="inner-btn" onClick={handleNextClick}>
                Перейти к следующей странице
            </button>
            {isDataSaved && <p>Данные успешно сохранены в Firestore.</p>}
        </div>
    );
};

export default Class9;
