import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, addDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

const Regions = () => {
    const navigate = useNavigate();
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedSchool, setSelectedSchool] = useState('');
    const [regions, setRegions] = useState([]);
    const [schoolsByRegion, setSchoolsByRegion] = useState({});

    useEffect(() => {
        const loadRegions = async () => {
            const regionsCollection = collection(db, 'regions');
            const regionsQuery = query(regionsCollection);
            const regionsSnapshot = await getDocs(regionsQuery);

            const regionsData = regionsSnapshot.docs.map((doc) => ({
                id: doc.id,
                name: doc.data().name,
            }));
            setRegions(regionsData);
        };

        loadRegions();
    }, []);

    const handleRegionChange = async (e) => {
        setSelectedRegion(e.target.value);
        setSelectedSchool('');

        const regionId = e.target.value;

        const loadSchoolsByRegion = async () => {
            const schoolsCollection = collection(db, 'regions', regionId, 'schools');
            const schoolsQuery = query(schoolsCollection);
            const schoolsSnapshot = await getDocs(schoolsQuery);

            const schoolsData = schoolsSnapshot.docs.map((doc) => ({
                id: doc.id,
                name: doc.data().name,
            }));

            setSchoolsByRegion({ [regionId]: schoolsData });
        };

        loadSchoolsByRegion();
    };

    const handleSchoolChange = (e) => {
        setSelectedSchool(e.target.value);
    };

    const handleNextClick = async () => {
        try {
            const selectedRegionName = regions.find(region => region.id === selectedRegion)?.name;
            const selectedSchoolName = schoolsByRegion[selectedRegion].find(school => school.id === selectedSchool)?.name;

            const selectedDataCollection = collection(db, 'selectedData');
            const docRef = await addDoc(selectedDataCollection, {
                region: selectedRegionName,
                school: selectedSchoolName,
            });

            localStorage.setItem('selectedDataDocId', docRef.id);

            navigate('/class7');
        } catch (error) {
            console.error('Ошибка при сохранении данных в Firestore:', error);
        }
    };


    return (
        <div className='center'>
            <h1>Добро пожаловать</h1>

            <div className='region-container'>
                <select value={selectedRegion} onChange={handleRegionChange}>
                    <option value="">Выберите регион</option>
                    {regions.map((region) => (
                        <option key={region.id} value={region.id}>
                            {region.name}
                        </option>
                    ))}
                </select>

                {selectedRegion && (
                    <select value={selectedSchool} onChange={handleSchoolChange}>
                        <option value="">Выберите школу</option>
                        {schoolsByRegion[selectedRegion] &&
                            schoolsByRegion[selectedRegion].map((school) => (
                                <option key={school.id} value={school.id}>
                                    {school.name}
                                </option>
                            ))}
                    </select>
                )}

                {selectedSchool && (
                    <button className='inner-btn' onClick={handleNextClick}>Перейти к следующей странице</button>
                )}
            </div>
        </div>
    );
};

export default Regions;
